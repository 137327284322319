import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

function Terms() {
    // onclick navigate back to homepage
    const navigate = useNavigate();
    const navigateBack = () => {
        navigate(-1);
    }
    return (
        <div className='privacy-policy-container' id="privacypolicy"> 
            <BsFillArrowLeftCircleFill className='back-arrow-icon'style={{ marginBottom: '25px'}} onClick={navigateBack}/>
            <div className='privacy-policy-content'>
                <h1 className='privacy-policy-title' style={{ textAlign: "" }}>Evoke Neurodiagnostics LLC Terms of Service</h1>
                <p><strong>Last Updated: January 1, 2025</strong></p>
                
                <p>
                This page explains the terms by which all users (“you” and “your”) may download, install, copy, access or use the Evoke Brain Training (“we”, “our” and “us”) software (App), including any future updates, upgrades or versions of the software (collectively, the “Software”) and any associated products and services made available by us or on our behalf from time to time including, but not limited to, our websites, widgets, computer programs, platforms, mobile applications, and training courses and programs (collectively, the “Services”) and accompanying documents, information, manuals or other related materials provided by us as part of, or in connection with the Software. By accessing or using the Software and/or the Services, you signify on your behalf and any person or entity that you represent that you have read, understood, and agree to be bound by these Terms of Service (the “Agreement”). We reserve the right to make unilateral modifications to these terms and will provide notice of these changes as described in Section 24 below.
                </p>
                <p>
                If you are a business, company, institution or other legal entity, you may permit Authorized Users to access and use the Software or the Services in compliance with this Agreement. “Authorized User(s)” mean the following users that you authorize to access or use the Services on your behalf: (a) your employees, agents, or independent contractors; (b) others such as clients, students, or subscribers where the Services is provided to your end user customers together with your other services and not on a standalone basis; or (c) other users expressly authorized to use and access the Services by us.   
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    The Software and the Services are for information purposes only and are not intended to be used for any other purpose, including medical or diagnostic-related purposes.
                </p>
                <ol type='1'>
                    <li>Use of the Software and Services</li>
                    
                        <p><strong>Eligibility</strong>This is a contract between you and us. By installing, downloading, copying, accessing or using the <strong>Evoke Brain Training</strong> Software (App) and/or the Services, or otherwise accepting this Agreement, or clicking “accept” (if applicable), you acknowledge that you have read, understood and agree to be bound by the terms of this Agreement. If you do not understand or agree, you must not install, download, copy, access or use the Software or the Services. Use of the <strong>Evoke Brain Training</strong> App is intended solely for the purpose of providing neurofeedback sessions and completing neuropsychological assessments via remote means. You agree not to use the App for any illegal, unauthorized, or unethical purposes. You are responsible for maintaining the confidentiality of your account credentials and agree to notify us immediately of any unauthorized access to your account.</p>
                        <p>You may use the Software and the Services only if you can form a binding contract with us, and only in compliance with this Agreement and all applicable:</p>
                        <ul >
                            <li>laws, constitutions, treaties, statutes, codes, ordinances, principles of common and civil law and equity, orders, decrees, rules, regulations and municipal by-laws, whether domestic, foreign or international;</li>
                            <li>judicial, arbitral, administrative, ministerial, departmental and regulatory judgments, orders, writs, injunctions, decisions, rulings, decrees and awards of any (i) multinational or supranational body or organization, nation, government, state, province, country, territory, municipality, quasi-government, administrative, judicial or regulatory authority, agency, board, body, bureau, commission, instrumentality, court or tribunal or any political subdivision thereof, or any central bank (or similar monetary or regulatory authority) thereof, any taxing authority, any ministry or department or agency of any of the foregoing; (ii) self-regulatory organization or stock exchange; (iii) entity exercising executive, legislative, judicial, regulatory or administrative functions of or pertaining to government; and (iv) corporation or other entity owned or controlled, through stock or capital ownership or otherwise, by any of such entities or other bodies pursuant to the foregoing (“Governmental Authority”); and </li>
                            <li>policies, practices and guidelines of, or contracts with, any Governmental Authority, which, although not actually having the force of law, are considered by such Governmental Authority as requiring compliance as if having the force of law, as the same may be amended from time to time and any successor thereto (collectively, “Applicable Laws”). </li>
                        </ul>
                        <br/>
                        <p>Any use or access to the Software or the Services by anyone under eighteen (18) years of age is strictly prohibited and in violation of this Agreement. By entering or using the Software or the Services you represent that you:  </p>
                        <ul>
                            <li>have the right, authority and capacity to enter into this Agreement on behalf of yourself and the person or entity that you represent (if applicable);</li>
                            <li>are not prohibited from entering or using the Software or the Services under any Applicable Laws;</li>
                            <li>are at least eighteen (18) years of age; and</li>
                            <li>understand the risks associated with using the Software and the Services.</li>
                        </ul>
                        <br/>
                        <p><strong>License Grant</strong> Subject to the terms and conditions of this Agreement and subject to your payment of all applicable fees (including applicable fees for all of your Authorized Users), we hereby grant you and your Authorized Users a limited, personal, non-transferable, non-sub-licensable, and non-exclusive license to install and use a single copy of the Software for your information and personal and internal business and research purposes, together with any applicable Services (the “License”). Except to the extent that you are authorized by us in writing (in which case additional and/or different terms and conditions may apply to you), you are not permitted to provide or resell the Software or the Services to anyone on a standalone basis. You agree to ensure that Authorized Users comply with the terms of this Agreement and all applicable fees for the Services are paid to us in relation to the use of the Services by all Authorized Users. We reserve all rights not expressly granted herein in the Software, the Services and our content. We may terminate this License, in whole or in part, at any time for any reason or no reason.</p>
                        <p>The rights granted to you in this Agreement are subject to the following restrictions: </p>
                        <ul>
                            <li>you shall not license, sell, rent, lease, transfer, assign, distribute, host, use or allow the use for the benefit of any third party or otherwise commercially exploit the Software or the Services, whether in whole or in part, or any content displayed on the Software or the Services;</li>
                            <li>you shall not remove or alter any copyright notice or any other notices; </li>
	                        <li>you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Software or the Services; </li>
	                        <li>you shall not access the Software or the Services in order to build a similar or competitive website, product, or service; </li>
                            <li>you shall not use, or allow the use, transfer, transmission, export, or re-export of the Software or the Services or portion thereof in violation of any Applicable Laws, including any export control laws or regulations;</li>
                            <li>you shall not interfere with or disrupt the integrity or performance of the Software or the Services; </li>
                            <li>you shall not attempt to gain unauthorized access to the Software, the Services or its related systems or networks; </li>
                            <li>you shall not use the Software or the Services in any manner that interferes with its normal operation or with any other user’s use of the Software or the Services; </li>
                            <li>you shall not probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; </li>
                            <li>you shall not send spam or otherwise duplicative or unsolicited messages in violation of Applicable Laws; </li>
                            <li>you shall not access, tamper with, or use non-public areas of our websites, our computer systems, or the technical delivery systems of its providers; </li>
                            <li>you shall not access or search or attempt to access or search our websites by any means other than through our currently available, published interfaces that are provided by us; </li>
                            <li>you shall not forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; </li>
                            <li>you shall not interfere with, or disrupt, the access of any user, host or network including sending a virus, overloading, flooding, spamming, mail-bombing the Services, or otherwise creating an undue burden on the Services; </li>
                            <li>you shall not use manual or automated software, devices, or other processes to “crawl,” “scrape,” or “spider” any page of our websites;</li>
                            <li>except as expressly stated herein, no part of the Software or the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means; and </li>
                            <li>you shall not use the Software or the Services other than as permitted hereunder. </li>
                        </ul>
                        <br/>
                        <p>Unless otherwise indicated, any future release, update, or other addition to the functionality of the Software and Services shall be subject to this Agreement. </p>
                        <br/>
                        <p><strong>User-Generated Content</strong> For the purposes of this Agreement, <strong>“User Content”</strong> means any text, feedback, ideas, suggestions, documents, proposals, photographs, electroencephalogram (EEG) recordings or other data and information you or anyone acting on your behalf submits to us including, without limitation, through online and mobile services made available by or on behalf of us from time to time including, but not limited to, the Services. </p>
                        <p>You represent your User Content: </p>
                        <ul>
                            <li>will not infringe any third party’s copyright, patent, trademark, trade secret or other proprietary rights, or rights of privacy;</li> 
                            <li>will not violate any Applicable Laws;</li>
                            <li>will not be obscene or contain child pornography; </li>
                            <li>will not contain software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs; and </li>
                            <li>will not be defamatory, unlawfully threatening or harassing, harmful to minors or others in any way, or otherwise offensive or inappropriate.</li>
                        </ul>
                        <br/>
                        <p><strong>Personally Identifiable User Generated Data </strong>For the purposes of this Agreement, <strong>“Personally Identifiable User Generated Data”</strong> means User Content that identifies you or your Authorized Users and is considered personal information in accordance with applicable privacy and data protection laws. For clarity, Personally Identifiable User Generated Data does not include de-identified and/or aggregated information. You hereby grant us a revocable, non-exclusive, worldwide, royalty-free license, to reproduce, distribute, transmit, publicly perform, publicly display, digitally perform, modify, create derivative works of, and otherwise use Personally Identifiable User Generated Data in any manner and at any time only so far as required to deliver the Software, our products and the Services. You waive any rights of publicity, moral or other similar right with respect to Personally Identifiable User Generated Data while used in accordance with the preceding sentence.</p>
                        <br/>
                        <p><strong>Non-Personally Identifiable User Generated Data </strong>For the purposes of this Agreement, <strong>“Non-Personally Identifiable User Generated Data”</strong> means all User Content except for Personally Identifiable User Generated Data. You hereby grant to us a perpetual, irrevocable, non-exclusive, worldwide, royalty-free license, with the right to sublicense, to reproduce, distribute, transmit, publicly perform, publicly display, digitally perform, modify, create derivative works of, and otherwise use and commercially exploit Non-Personally Identifiable User Generated Data in any manner and at any time. You waive any rights of publicity, moral or other similar right with respect to the Non-Personally Identifiable User Generated Data.</p>
                        <br/>
                        <p><strong>No Representation Regarding Local Laws </strong>We make no representation that the Software, the Services and/or the materials contained, described or offered by the Software and/or Services are accurate, appropriate or available for use in all jurisdictions, or that this Agreement complies with the laws of any particular country. Users of the Software and the Services use the Software and the Services on their own initiative and are responsible for compliance with all Applicable Laws. Notwithstanding anything to the contrary, you agree that you will not access the Software or the Services from any territory where it is illegal, and that you are solely responsible for compliance with all Applicable Laws.</p>
                        <br/>
                        <p><strong>Training Courses, Programs and Services </strong>We may make online self-help as well as custom training courses, programs and services available to you from time-to-time. Any and all training content and materials are provided as-is. We do not guarantee these resources to be free from errors or to be an accurate representation of the current capabilities of the Services.</p>
                        
                        <p>If you have purchased any of our custom training packages, you are fully responsible for providing us with any and all information that we need to customize and complete the training program. You are also fully responsible for ensuring that all applicable personnel are made available at the designated times outlined in the training curriculum and schedule. The number of hours and sessions included in any custom training package will be listed in the package description at the time of purchase. Unless otherwise stated in the training package description, training will be conducted remotely using remote presentation tools, is for up to five (5) trainees and will be delivered over the course of one (1) month. We have no obligation to repeat any training sessions if you or any of your personnel fail to attend or if the training content does not meet your expectations.</p>
                        
                        <p>All right, title and interest in and to the training materials belongs solely to us. If you have purchased and paid for a custom training program, we will grant you and your specified trainees a limited, personal, non-transferable, non-sub-licensable, and non-exclusive license to use and reproduce one copy of the training materials provided to you by us solely for the purposes of such applicable training program and following completion of such training program solely for your own internal business purposes.</p>
                    
                    <li><strong>Accounts</strong>You may be required to establish an account and pay any fees through payment processors in order to use the Software and/or the Services. </li>
                        <p>If so, you agree to:</p>
                        <ul>
                            <li>establish and use only one account with us;</li>
    	                    <li>provide true, accurate, current and complete information about yourself as prompted by the Software or Services; and</li>
                            <li>as permitted, maintain and promptly update such information. If you provide any information that is false, inaccurate or outdated, or we have reasonable grounds to suspect that such information is false, inaccurate, outdated, or non-compliant with the terms of this Agreement or the Privacy Policy, we have the right to suspend or terminate and/or cancel your account and/or prohibit any and all current or future use of the Software and/or Services by you.</li>
                        </ul>
                        <br/>
                        <p>You are responsible for maintaining the confidentiality of your unique username, password, access code or other sign-in mechanism and all other account information (collectively, “Account Information”) and your account and are fully responsible for all activities that occur under your account. You agree you will not share your Account information with anyone and if anyone else has access to your Account Information, then you are responsible for any of their actions. You agree to prevent unauthorized access to your account by selecting and protecting your Account Information in accordance with current reasonable security standards and limiting access to your computer, browser, or mobile device by signing off after you have finished accessing your account. You agree to immediately notify us at <a href="mailto:info@evokediagnostics.com ">info@evokediagnostics.com </a>of any unauthorized use of your Account Information or account or any other breach of security. You agree to be responsible for all charges resulting from the use of your account and will indemnify us for any losses we suffer due to your use or misuse of the Software and/or Services including charges resulting from any unauthorized use of your account. Unless otherwise agreed to between you and us, user subscriptions cannot be shared or used by more than one individual user. </p>
                        <p>Notwithstanding anything to the contrary in this Agreement, you acknowledge and agree that we may access, use, preserve and/or disclose your Account Information if legally required to do so or if we have a good faith belief that such access, use, disclosure, or preservation is reasonably necessary to:</p>
                        <ul>
                            <li>comply with legal process or request; </li>
                            <li>enforce the Agreement including investigation of any potential violation thereof; </li>
                            <li>detect, prevent or otherwise address security, fraud or technical issues; or</li> 
                            <li>protect our rights, property or safety of the rights, property or safety of our users or the public as considered necessary by us or permitted by Applicable Law.</li>
                        </ul>
                        <br/>
                    <li><strong>User Conduct</strong></li>
                        <p>If we determine in our sole discretion that you are not using the Software or the Services responsibly, we have the right (but not the obligation) to remove, edit, block or delete your transmissions, User Content, or use of the Software and/or the Services. We may, without prior notice, change the Software or the Services; stop providing the Software or the Services or features of the Software or the Services to you or to users generally; or create usage limits for the Software or the Services. We may permanently or temporarily terminate or suspend your access to the Software or the Services without notice and liability for any reason, including if in our sole determination you violate any provision of this Agreement, or for no reason. Upon termination for any reason or no reason, you continue to be bound by this Agreement. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Software or the Services or any part thereof. You are solely responsible for your interactions with other users of the Software and the Services. We reserve the right, but have no obligation, to monitor disputes between you and other users. We shall have no liability for your interactions with other users, or for any user’s action or inaction.</p>
                    <li><strong>Fees</strong></li>
                        <p><strong>Compensation</strong> You will pay amounts specified in each invoice or subscription issued by us. You must make payments in advance, unless the applicable invoice or subscription contains payment terms that are different. Unless otherwise specified in the invoice or subscription, unpaid amounts are subject to interest equal to the lesser of (i) 1.5% per month (18% per annum) or (ii) the maximum permitted by Applicable Laws, on any outstanding balance, plus all expenses of collection and may result in immediate termination and/or cancellation of your access to or use of the Software and/or the Services. You will be responsible for all taxes associated with the Services other than taxes based on our net income. Your access to and/or use of the Services may be terminated or cancelled without notice for any unpaid and due amount. We reserve the right to impose a reconnection fee in the event you are suspended and thereafter request access to the Services. Unless otherwise specified in the invoice or subscription, all amounts under this Agreement are payable in United States dollars. Any amounts paid by you are non-refundable.</p>
                        <p><strong>Compensation Collection</strong> Payment processing services for the Services are provided by a payment processor and you are subject to the applicable payment processor services agreement. By agreeing to the Agreement or continuing to use the Services, you agree to be bound by such payment processor services agreement, as it may be modified by the payment processor from time to time.</p>
                        <p><strong>Changes to Fees</strong>Subject to your agreement to pay additional amounts, incremental fees may be charged where new or additional functions are added or become available to you.</p>
                        <p><strong>Non-Circumvention of Fees </strong>You shall not circumvent fees that would otherwise be due and payable, including without limitation creating multiple user accounts or accessing, modifying, tampering with, exploiting or otherwise using the Services and/or the Software to circumvent fees.</p>
                        <p><strong>Gift and Promotional Codes </strong>We or our partners may offer gift and promotional codes to users of the Services. Certain codes may be redeemed for gift or promotional credits applied to your account, which then may be used to purchase eligible content on our platform, subject to the terms included with your codes. Other codes may be directly redeemable for specific content. These codes and credits, as well as any promotional value linked to them, may expire if not used within the period specified in your account. Gift and promotional codes offered by us may not be refunded for cash, unless otherwise specified in the terms included with your codes or as required by applicable law. Gift and promotional codes offered by a partner are subject to that partner’s refund policies. If you have multiple saved credit amounts, we may determine which of your credits to apply to your purchase. Please review any terms included with your codes for more details.</p>
                        <p><strong>Reports, Records and Audit </strong>You must ensure that you, and anyone representing you, comply with our ordering and reporting requirements in effect from time-to-time, which may include providing us with written reports on the use of the Services by you and your Authorized Users in the format, for the period and within the timelines as specified by us. To permit us to confirm compliance with this Agreement and Applicable Laws, while this Agreement remains in effect and for a period of two (2) years thereafter, you must keep accurate records including records about: </p>
                        <ul>
                            <li>the use of the Services by you and your Authorized Users;</li>
                            <li>any use of our trademarks and other intellectual property; and</li>
                            <li>compliance with the obligations of this Agreement. To assure such compliance, we and/or our auditors may inspect, both while this Agreement remains in effect and for a period of two (2) years thereafter, such records from time-to-time. Any such audits shall be conducted during regular business hours and shall not interfere unreasonably with your normal activities. If an audit reveals that you, or an Authorized User, has not complied with the obligations of this Agreement, you may be required to pay our reasonable costs of conducting the audit.</li>
                        </ul>
                        <br/>
                    <li><strong>Intellectual Property</strong></li>
                        <p>The Software and the Services are protected by copyright, patent, trademark, trade secret and other intellectual property rights and laws. As between you and us, we retain all rights, title, interest, ownership and intellectual property rights in and to the Software and the Services. The License confers no title or ownership in the Software or the Services and is not a sale of any rights in the Software or the Services. The License does not grant you any right to any enhancement or update to the Software and the Services. We reserve any and all rights not expressly granted to you. The Software and/or Services may incorporate third party intellectual property or open source code.</p>
                    <li><strong>Confidentiality and Publicity</strong></li>
                        <p>In connection with the Software and the Services provided to you under this Agreement, you may, from time to time, be exposed to and may be furnished with certain information, material or data relating to the Software and the Services that are either specifically identified as confidential prior to or at the time of disclosure or would reasonably be considered confidential and/or proprietary to us (“Confidential Information”). During the term of this Agreement and for a period of five (5) years thereafter, you will keep confidential and not reveal or disclose to any third party any such Confidential Information. You will be directly liable for the acts or omissions of your employees, agents, contractors and other authorized parties with respect to such confidentiality obligations. You agree to protect the Confidential Information with the same standard of care and procedures which you use to protect your own trade secrets, proprietary information and other confidential information and, in any case, not less than a reasonable standard of care. </p>
                        <p>Confidential Information includes, without limitation: any test results relating to the Services; </p>
                        <ul>
                            <li>the Software source code;</li>
                            <li>technical specifications related to the Services; </li>
                            <li>any non-publicly available pricing; and </li>
                            <li>except for User Content, all technology, know-how, algorithms, testing procedures, software, structure, interfaces, specifications, reports, analysis and other technical information learned, accessed or derived by you pursuant to this Agreement or pursuant to your use of or access to the Software and/or the Services. Except where you are merely evaluating the Software or the Services, you agree we will have the right to issue a press release or otherwise publicize your use of the Software or the Services and use your logo on our websites and other marketing materials.</li>
                        </ul>
                        <br/>
                    <li><strong>Not Medical Devices or Services</strong></li>
                        <p>For Information Purposes Only <strong></strong>Content generated or found through the Software, the Services or our products is for informational purposes only and is not intended to replace the relationship between you and your physician, healthcare provider or other professional you are working with (collectively, “Professionals”). You acknowledge and agree that our products, the Software and the Services, are not medical devices or medical services, and we are not a licensed medical or healthcare provider and have no expertise in diagnosing, examining, or treating medical conditions of any kind. </p>
                        <p>Physical Symptoms <strong></strong>In rare cases, people may experience seizures or blackouts due to exposure to flashing lights and patterns created by the display of certain applications on mobile or other such similar devices used in conjunction with the Software or the Services. If you have done so, or have experienced any nausea, involuntary movements, tingling, numbness, or vision issues while using such devices in the past, you should consult with an appropriate Professional before using similar applications and should immediately cease all such use of such applications should the symptoms reoccur. In any event you should avoid prolonged use of such applications to minimize any possible discomfort or fatigue, including any muscle, joint or eye strain.</p>
                    <li><strong>Support to End Users</strong></li>
                        <p>Unless otherwise agreed to in writing by us, we shall not be responsible for supporting any End Users of the Services. You shall ensure that you and/or your designee support all End Users of the Services, including, at a minimum, answering and responding to telephone calls and email, logging calls and messages, problem determination and providing known work-arounds and solutions to problems. You shall use reasonable efforts to ensure all End Users contact you and/or your designee directly for support. If, notwithstanding such efforts, we are being contacted by a significant number of End Users, you and we will cooperate to develop procedures and practices to minimize such contact.</p>
                    <li><strong>Third-Party Links and Information</strong></li>
                        <p>The Services may contain links to third-party materials that are not owned or controlled by us. We do not endorse or assume any responsibility for any such third-party websites, information, materials, products, or services. If you access a third-party website or service from the Services on or through any third-party website or service, you do so at your own risk, and you understand that this Agreement and our Privacy Policy do not apply to your use of such websites. You expressly relieve us from any and all liability arising from your use of any third-party website, service, or content. Additionally, your dealings with or participation in promotions of advertisers found on the Services, including payment and delivery of goods, and any other terms (such as warranties) are solely between you and such advertisers. You agree that we shall not be responsible for any loss or damage of any sort relating to your dealings with such advertisers.</p>
                    <li><strong>Termination</strong></li>
                        <p>This Agreement is effective until terminated. You may terminate this Agreement at any time by uninstalling the Software and destroying all copies of the Software in your possession or control and ceasing to use all Services. We may terminate this Agreement immediately upon notice to you for any or no reason. The Agreement will terminate immediately without notice from us if you fail to comply with any provision of this Agreement. Upon termination of this Agreement, you agree to immediately uninstall the Software and destroy all copies of the Software and cease using all Services.</p>
                    <li><strong>Indemnity</strong></li>
                        <p>You agree to defend, indemnify and hold harmless us and our suppliers, partners, licensors, dealers, representatives, associates or affiliates, and each of their respective employees, contractors, agents, advisors, representatives, shareholders, officers and directors (collectively, the “Evoke Parties”), from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to legal fees and disbursements) arising from: </p>
                        <ul>
                            <li>your use of and access to the Software or the Services, including any act or omission by you or users of your account or any data or content transmitted or received by you; </li>
                            <li>your violation of any term of this Agreement, </li>
                            <li>your violation of any third-party right, including without limitation any right of privacy or intellectual property rights; </li>
                            <li>your violation of any Applicable Laws; </li>
                            <li>any User Content that you submit to the Software or the Services including without limitation misleading, false, or inaccurate information; </li>
                            <li>your willful misconduct; or </li>
                            <li>any other party’s access and use of the Software or the Services with your Account Information. You will cooperate as fully as reasonably required in the defense of any claim.</li>
                        </ul>
                        <br/>
                    <li><strong>No Warranty</strong></li>
                        <p>The Software and the Services are provided on an “as is”, “as available” and “with all faults” basis. Use of the Software and the Services is at your own risk. To the maximum extent permitted by Applicable Laws, the Software and the Services are provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. No advice or information, whether oral or written, obtained by you from an Evoke Party or through the Software or the Services will create any warranty not expressly stated herein. Without limiting the foregoing, the Evoke Parties do not warrant that the content is accurate, reliable or correct; that the Software and the Services will meet your requirements; that the Software and the Services will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Software and the Services are free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Software, or the Services is downloaded at your own risk, and you will be solely responsible for any damage to your computer system or mobile device or loss of data that results from such download or your use of the Software or the Services.</p>
                    <li><strong>Disclaimer</strong></li>
                        <p>None of the Evoke Parties will have any responsibilities or liability with respect to the following: </p>
                        <ul>
                            <li>the Software or the Services could be impacted by one or more regulatory inquiries or actions, which could prevent or limit our ability to continue to develop or provide the Software or the Services, or for you and your users to use the Software or the Services, </li>
                            <li>we have no obligation to update the Software, the Services or each of their underlying platforms and networks to address, mitigate, or remediate any security or other vulnerabilities in the Software or the Services, or such platforms or networks, and</li> 
                            <li>portions of the Software or the Services or any other underlying networks and platforms may rest on open-source software, and there is a risk that weaknesses or bugs that may be introduced in the infrastructural elements of the Services or any other underlying networks and platforms, which may result in security vulnerabilities, data loss, damage, destructions, disclosure, or other compromises.</li>
                        </ul>
                        <br/>
                    <li><strong>Assumption of Risk; Limitation of Liability</strong></li>
                        <p><strong>Assumption of Risk</strong>You acknowledge and agree that we do not warrant that the Software is error free. You expressly agree that you assume all risk in connection with your access and use of the Software and the Services.</p>
                        <p><strong>Limitation of Liability</strong>You also agree that to the maximum extent permitted by Applicable Laws, in no event shall any Evoke Party be liable for any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Software or the Services. Under no circumstances will any Evoke Party be responsible for any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Software, the Services or the information contained therein. To the maximum extent permitted by Applicable Laws, we assume no liability or responsibility for: 
                            the installation, download, copying, access to or use of the Software or the Services, 
                            any errors, mistakes, or inaccuracies of content; 
                        </p>
                        <ul>
                            <li>personal injury or property damage, of any nature whatsoever, resulting from your access to or use of the Software or the Services;</li>
                            <li>any unauthorized access to or use of our secure servers and/or any and all personal information stored therein; </li>
                            <li>any interruption or cessation of transmission to or from the Software or the Services; </li>
                            <li>any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Software or the Services by any third party; </li>
                            <li>any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Software or the Services; and </li>
                            <li>User Content or the defamatory, offensive, or illegal conduct of any third party. </li>
                        </ul>
                        <p>You agree that if, notwithstanding the other provisions of this Agreement, a Evoke Party is found to be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs, such Evoke Party’s liability shall in no event exceed the amount paid by you for the Software or the Services in the one (1) month immediately preceding the event giving rise to your claim, if any.</p>
                    <li><strong>Limitations as Allowed by Law</strong></li>
                        <p>Some provinces, territories, states, districts and other jurisdictions do not allow the exclusion and limitations of certain implied warranties, or the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you. This Agreement gives you specific legal rights, and you may also have other rights which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under this Agreement will not apply to the extent prohibited by Applicable Laws.</p>
                    <li><strong>Exclusions Fundamental to this Agreement</strong></li>
                        <p>The disclaimers of warranties and limitations of liability set forth in this Agreement are fundamental elements of the basis of the agreement between you and us. You understand and agree that we would not be able to economically or reasonably provide the Software or the Services to you without these limitations.</p>
                    <li><strong>Non-Waiver</strong></li>
                        <p>Our failure or delay in insisting upon or enforcing strict performance of any provision of this Agreement shall not be construed as a waiver of any provision or rights. No waiver of any provision of this Agreement shall constitute a waiver of any other provision, nor shall any waiver of any provision of this Agreement constitute a continuing waiver unless expressly made in writing by us.</p>
                    <li><strong>Severability</strong></li>
                        <p>In the event that any of the provisions of this Agreement are held by a court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that these terms and conditions shall otherwise remain in full force and effect.</p>
                    <li><strong>Governing Law and Jurisdiction</strong></li>
                        <p>This Agreement is entered into in the State of Oklahoma and shall be governed by, and construed in accordance with, the laws of the State of Oklahoma and the federal laws of the United States applicable therein. You agree to submit to the exclusive jurisdiction of the courts of the State of Oklahoma or any other judicial district or jurisdiction as we may determine in any and all actions, disputes or controversies relating hereto. The United Nations Convention on Contracts of the International Sale of Goods is expressly excluded. You further agree as follows: </p>
                        <ul>
                            <li>any claim brought to enforce these terms and conditions must be commenced within two (2) years of the cause of action accruing;</li>
                            <li>no recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and legal fees; </li>
                            <li>your sole and exclusive remedy shall be for monetary damages only and you hereby waive all rights to equitable remedies; and </li>
                            <li>any claim must be brought individually and not consolidated as part of a group or class action complaint.</li>
                        </ul>
                        <br/>
                    <li><strong>Entire Agreement</strong></li>
                        <p>This Agreement, including all other documents incorporated by reference herein, are binding and constitute the entire agreement between us and you with respect to your use of the Software and the Services.</p>
                    <li><strong>Amendments</strong></li>
                        <p>We reserve the right, in our sole discretion, to modify the terms of this Agreement from time to time. If we make changes, we will provide you with notice of such changes, such as by providing notice through the Services or updating the “Last Updated” date at the top of this Agreement. Unless we state otherwise in our notice, all such modifications are effective immediately, and your continued use of the Services after we provide that notice will confirm your acceptance of the changes. If you do not agree to the amended terms of this Agreement, then your only recourse is to terminate this Agreement by providing written notice to us and must stop using the Services.</p>
                    <li><strong>Language</strong></li>
                        <p>The parties agree that the English language will be the language of the Agreement and all documents in connection thereto, and each party waives any right (whether statutory or otherwise) to use and rely upon any other language, or translations.</p>
                    <li><strong>Privacy Policy</strong></li>
                        <p>You acknowledge and agree that you have read our Privacy Policy made available to you through the Software and/or the Services and consent to the handling and processing of your data as described in our Privacy Policy.</p>
                    <li><strong>Contact Us</strong></li>
                        <p>If you have any questions about this Privacy Policy, please contact us at:  <a href="mailto:info@evokediagnostics.com">info@evokediagnostics.com</a>.</p>
                    <li><strong>Acknowdledgement</strong></li>
                        <p>By using the <strong>Evoke Brain Training</strong> App, you acknowledge that you have read, understood, and agree to abide by these Terms and Conditions and the Privacy Policy.</p>













                    



                      
                </ol>
               
                
            </div>
    </div>
    )
}

export default Terms;

